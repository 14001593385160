<template>
  <div
    v-if="profile"
    class="p-relative"
  >
    <ProfileDetails
      class="bg-white b-all bc-form-border br-5"
      :node="profile"
      :big="true"
      :hide-checkbox="true"
      :firstElem="true"
    />
    <a
      v-if="!child && !editMode"
      @click.prevent="openEditMode"
      class="p-absolute top-0 right-0 bottom-0 fs-14 lh-50 color-link color-link-lighter_hover transition mr-24"
    >Find connection between</a>
    <a
      v-else
      @click.prevent="removeProfile"
      class="p-absolute top-0 right-0 bottom-0 lh-50 transition color-text-lighter color-link_hover px-24"
    >
      <Icon name="trash-no-fill" />
    </a>
  </div>

  <div v-else>
    <input
      type="text"
      v-model.trim="textSearch"
      placeholder="Type the subject's name, alias, company, education, or location"
      class="input"
      :class="{ 'br-top-left-5 br-top-right-5 br-bottom-left-0 br-bottom-right-0': results.length }"
    />
    <div
      v-show="results.length"
      class="h-342px overflow-x-hidden custom-scrollbar bg-form b-left b-right b-bottom br-bottom-left-5 br-bottom-right-5 bc-form-border"
    >
      <ProfileDetails
        v-for="(profile, index) in results"
        :key="profile.oid"
        :node="profile"
        :big="true"
        :hide-checkbox="true"
        :firstElem="index === 0"
        class="bg-white"
        @click="checkProfile(profile)"
      />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/app/Icon';
import ProfileDetails from '@/components/UI/ProfileDetails';
import { mapGetters } from 'vuex';

export default {
  components: {
    Icon,
    ProfileDetails
  },
  emits: ['changeEditMode', 'sendProfile'],
  props: {
    profile: { default: null },
    editMode: { default: false },
    child: { default: false },
    parentTypeId: { default: null }
  },
  data () {
    return {
      textSearch: '',
      results: [],
      visibleOIDs: []
    };
  },
  watch: {
    textSearch () {
      const query = this.textSearch.toLowerCase();
      this.results = [];
      this.visibleOIDs = [];
      const searchFields = [
        'id', 'name', 'alias', 'info', 'affiliation.uid', 'created_at', 'location', 'person.name', 'twitter.id',
        'twitter.screen-name', 'description', 'experience', 'industry', 'occupation', 'lives', 'location_name',
        'username'
      ];

      [this.currentProfiles, this.relationProfiles].forEach(profiles => {
        profiles.forEach(profile => {
          searchFields.forEach(fld => {
            if (profile.fields[fld] && (profile.fields[fld] || '').toLowerCase().includes(query) && !this.visibleOIDs.includes(profile.oid) && this.results.length <= 100) {
              this.results.push(profile);
              this.visibleOIDs.push(profile.oid);
            }
          });
        });
      });

      if (query.length === 0) this.results = [];
    }
  },
  computed: {
    ...mapGetters(['getCurrentResults', 'getCurrentRelations']),

    currentProfiles () {
      if (this.child && this.parentTypeId) return this.getCurrentResults.filter(result => result.typeid === this.parentTypeId);
      return this.getCurrentResults;
    },
    relationProfiles () {
      const profiles = [];
      this.getCurrentRelations.forEach(relation => {
        profiles.push({ ...relation.childProfile });
      });
      return profiles;
    }
  },
  methods: {
    openEditMode () {
      if (!this.child) {
        this.$emit('changeEditMode', true);
        this.$emit('sendProfile', { profile: this.profile, child: this.child });
      }
    },
    checkProfile (profile) {
      profile.cid = this.$route.params.id;
      this.textSearch = '';
      this.$emit('sendProfile', { profile: profile, child: this.child });
    },
    removeProfile () {
      this.textSearch = '';
      this.$emit('sendProfile', { profile: null, child: this.child });
    }
  }
};
</script>
