<template>
  <Layout>
    <template v-slot:sidebar>
      <SidebarCase />
    </template>
    <template v-slot:content>
      <ConnectionsResults />
    </template>
  </Layout>
</template>

<script>
import Layout from '@/components/UI/Layout';
import SidebarCase from '@/viewsNew/SidebarCase';
import ConnectionsResults from '@/components/connections/Results';

export default {
  components: {
    Layout,
    SidebarCase,
    ConnectionsResults
  }
};
</script>
