<template>
  <ConnectionsForm />

  <template v-if="getCurrentRelations.length">
    <h3 class="text-h3 mb-24">{{ getCurrentRelations.length }} connected profiles</h3>

    <Filter
      v-if="getCurrentRelations.length > 1"
      :getters="{ current: getCurrentRelations, visible: getVisibleRelations, checked: getCheckedRelations }"
      text-query="childProfile"
      type="connections"
      :show-filter="{ scores: true, tags: true, socialNetworks: false, locations: false }"
    />

    <div class="mb-32">
      <div class="text-link no-hover color-text-darker mb-12">Connection score</div>
      <ul class="text-base">
        <li class="my-4"><span class="color-text-darker">Friend (+50 points) –</span> the profile is in the subjects’s friend list</li>
        <li class="my-4"><span class="color-text-darker">Tagged with (+30 points) –</span> the person was tagged in a photo with the subject</li>
        <li class="my-4"><span class="color-text-darker">Liked post/photo (+3 points) –</span> the person liked the user's post or photo</li>
        <li class="my-4"><span class="color-text-darker">Commented post/photo (+3 points) –</span> the person commented on the  subject's post or photo</li>
      </ul>
    </div>

    <template
      v-for="(connection, index) in limitedRelations"
      :key="connection.oid"
    >
      <Waypoint
        :active="(index + 1) === limitedRelations.length"
        :disableCssHelpers="true"
        @change="waypointMethod"
      >
        <ConnectionsCard
          :connection="connection"
        />
      </Waypoint>
    </template>
  </template>
  <Controller
    v-if="Object.keys(getCheckedValues).length"
    type="connections"
    :profiles="resultsForController"
  />
</template>

<script>
import ConnectionsForm from '@/components/connections/Form';
import Filter from '@/components/UI/Filter';
import ConnectionsCard from '@/components/connections/Card';
import Controller from '@/components/controller/Template';
import { Waypoint } from 'vue-waypoint';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    ConnectionsForm,
    Filter,
    ConnectionsCard,
    Waypoint,
    Controller
  },
  data () {
    return {
      limitToShow: 5,
      limitStep: 5
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.setCurrentRelations();
      }
    }
  },
  computed: {
    ...mapGetters(['getCurrentRelations', 'getVisibleRelations', 'getCheckedRelations', 'getCheckedValues']),

    limitedRelations () {
      if (this.getVisibleRelations) return this.getVisibleRelations.slice(0, this.limitToShow);
      else return [];
    },
    resultsForController () {
      const arr = [];
      for (const key of Object.keys(this.getCheckedValues)) {
        arr.push(this.getCheckedValues[key]);
      }
      return arr;
    }
  },
  methods: {
    ...mapActions(['setCurrentRelations']),

    waypointMethod (waypointState) {
      if (waypointState.going === 'IN') {
        this.limitToShow += this.limitStep;
      }
    }
  }
};
</script>
