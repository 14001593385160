<template>
  <div class="loader-block" v-if="getSearchingHACK">
    <img src="@/assets/icons/searching.gif" alt="" class="h-78">
  </div>

  <div class="bg-form bs-default br-5 p-24 mb-32">
    <div
      v-if="getCurrentRelations.length"
      class="d-flex align-center jc-space-between mb-24"
    >
      <h4 class="text-h4">{{ editMode ? 'Find connections between' : 'Explore connections' }}</h4>
      <!-- <button
        v-if="!editMode"
        class="text-link f-flex align-center"
      >
        <Icon name="refresh" class="transition" />
        <span class="ml-4">Refresh (15 SLP)</span>
      </button> -->
    </div>
    <div
      v-else
      class="mb-24"
    >
      <h4 class="text-h4">Sorry, but we didn't find anything, please try to change the input data</h4>
    </div>

    <div
      v-if="connectionsMode"
      class="p-relative"
    >
      <ProfileDetails
        class="bg-white b-all bc-form-border br-5"
        :node="getCurrentRelations[0]"
        :big="true"
        :hide-checkbox="true"
        :firstElem="true"
      />
      <a
        @click.prevent="changeMode"
        class="p-absolute top-0 right-0 bottom-0 fs-14 lh-50 color-link color-link-lighter_hover transition mr-24"
      >Find connection between</a>
    </div>
    <ConnectionsPanel
      v-else
      :profile="parentProfile"
      :edit-mode="editMode"
      @changeEditMode="editMode = true"
      @sendProfile="getProfile"
    />

    <template v-if="editMode">
      <div class="d-flex align-center text-base color-text-lighter my-15">
        <div class="mr-20">
          <div class="br-circle bg-text-lighter w-4px h-4px mb-5"></div>
          <div class="br-circle bg-text-lighter w-4px h-4px"></div>
          <div class="br-circle bg-text-lighter w-4px h-4px mt-5"></div>
        </div>
        <div>and</div>
      </div>
      <ConnectionsPanel
        :profile="childProfile"
        :edit-mode="editMode"
        :child="true"
        :parentTypeId="typeId"
        @sendProfile="getProfile"
      />
    </template>

    <div class="d-flex align-center jc-space-between mt-16">
      <a
        class="d-flex align-center text-link"
        @click.prevent="backToSearch"
      >
        <Icon name="arrow-left" class="h-12px mr-12" />
        <span>Back to profile search</span>
      </a>

      <button
        v-if="editMode"
        :disabled="!enableSearch"
        @click="search"
        class="bttn-primary w-260px"
      >
        Show results (30 SLP)
      </button>

      <!-- <button
        v-if="getCurrentRelations.length"
        class="bttn-primary w-260px"
      >
        Fast report
      </button> -->
    </div>
  </div>
</template>

<script>
import smartlookClient from 'smartlook-client';
import ProfileDetails from '@/components/UI/ProfileDetails';
import Icon from '@/components/app/Icon';
import ConnectionsPanel from '@/components/connections/Panel';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    ProfileDetails,
    Icon,
    ConnectionsPanel
  },
  data () {
    return {
      connectionsMode: false,
      editMode: false,
      parentProfile: null,
      childProfile: null
    };
  },
  watch: {
    $route: {
      immediate: true,
      async handler () {
        this.connectionsMode = false;
        this.editMode = false;
        this.childProfile = null;
        this.setCurrentResults();
        await this.setCurrentRelations();
        this.parentProfile = this.getCurrentRelTargets;
        if (this.getCurrentRelations.length === 1) {
          this.connectionsMode = true;
          this.childProfile = this.getCurrentRelations[0].childProfile;
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getCurrentRelations', 'getCurrentRelTargets', 'getSearchingHACK']),

    typeId () {
      if (this.parentProfile) return this.parentProfile.typeid;
      else return null;
    },
    enableSearch () {
      return this.parentProfile && this.childProfile;
    }
  },
  methods: {
    ...mapActions(['findConnections', 'setCurrentResults', 'setCurrentRelations']),

    changeMode () {
      this.connectionsMode = false;
      this.editMode = true;
    },
    backToSearch () {
      this.$router.push(`/case/${this.$route.params.id}/${this.$route.params.searchId}`);
    },
    getProfile (val) {
      if (val.child) this.childProfile = val.profile;
      else this.parentProfile = val.profile;
    },
    async search () {
      smartlookClient.track('spent_slp', { where: 'find connections between', quantity: 30 });
      await this.findConnections([this.parentProfile, this.childProfile]);
    }
  }
};
</script>
